<div>
  <div class="app-container app-header-bar">
    <div class="app-header-bar-item"></div>
    <h5 class="app-header-bar-title app-font-regular">Смелый, мощный, невероятный! Мы это революция в РУ-сегменте DND
    </h5>
    <div class="app-header-bar-item">
      <a class="app-header-bar-social"
        href="//boosty.to/dnd-campaign"
        target="_blank">
        <div class="app-font-regular">Поддержите нас</div>
        <img src="assets/icons/social/boosty-big-dark.svg"
          alt="Подписаться на DND Campaign на Boosty">
      </a>
    </div>
  </div>
  <div class="app-container heading-meet">
    <div class="heading-meet-title">
      Познакомьтесь с DND Campaign — сервисом для мастеров и игроков по управлению персонажами, играми и лором.
    </div>
    <app-socials></app-socials>
  </div>
</div>
<div class="app-about-page-container-image"></div>
<app-scroller data="greetings"
  color="red">
</app-scroller>