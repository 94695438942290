import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-scroller',
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollerComponent {
  @Input() data!: string;
  @Input() color = '#000000';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  scroll() {
    this.document.getElementById(this.data)?.scrollIntoView();
  }
}
