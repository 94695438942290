<a class="app-social app-social-normalize-height"
  href="//youtube.com/@dnd-campaign"
  target="_blank">
  <img src="assets/icons/social/youtube-transparent.svg"
    alt="DND Campaign на YouTube">
</a>
<a class="app-social"
  href="//t.me/dnd_campaign"
  target="_blank">
  <img src="assets/icons/social/telegram-dark.svg"
    alt="DND Campaign в Telegram">
</a>
<a class="app-social"
  href="//vk.com/dnd_campaign"
  target="_blank">
  <img src="assets/icons/social/vk-black.svg"
    alt="DND Campaign в ВК">
</a>
<a class="app-social app-social-normalize-height"
  href="//boosty.to/dnd-campaign"
  target="_blank">
  <img src="assets/icons/social/boosty-small-dark.svg"
    alt="DND Campaign на Boosty">
</a>