<router-outlet></router-outlet>

<ng-template #cookieTemplate>
  <div style="line-height: 24px;">
    Наш сайт использует файлы <a routerLink="/docs/cookie">cookie</a> для сбора аналитики, оставаясь на <a
      [href]="'//' + url">DND Campaign</a> Вы соглашаетесь с их использованием.
    <a class="app-pointer"
      (click)="acceptCookie()">
      [Закрыть]
    </a>
  </div>
</ng-template>