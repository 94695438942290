export interface PageComponentData {
  id: string;
  nextId: string;
  title: string;
  content: string;
  images: { url: string; title: string }[];
}

export const ADVENTURES_PAGE: PageComponentData = {
  id: 'adventures',
  nextId: 'characters',
  title: 'ВАШЕ ПУТЕШЕСТВИЕ — ЭТО ВАША КАМПАНИЯ, А ВАША КАМПАНИЯ — ЭТО ВАШИ ЭМОЦИИ!',
  content:
    'СПИСКИ ВАШИХ ИГР, ВАШИХ ПЕРСОНАЖЕЙ И ВАШЕГО УНИКАЛЬНОГО КОНТЕНТА, КОТОРЫЙ ВЫХОДИТ ЗА РАМКИ SRD. ВСЁ ЭТО ДОСТУПНО ТОЛЬКО ВАМ И ИГРОКАМ ВАШИХ КАМПАНИЙ. ПЛАНИРУЙТЕ ИГРЫ ПО-НОВОМУ УЧИТЫВАЯ ВЕСЬ БЭКГРАУНД. СОЗДАВАЙТЕ ТОРГОВЦЕВ И ИХ ИНВЕНТАРЬ ЗАРАНЕЕ. ПРОЕКТИРУЙТЕ NPC И ИХ СПОСОБНОСТИ ДЛЯ БУДУЩИХ СРАЖЕНИЙ. ПРОПИСЫВАЙТЕ ПЕРСОНАЖЕЙ, ИХ СПОСОБНОСТИ, ПРОШЛОЕ И НАСТОЯЩЕЕ.',
  images: [
    { url: 'adventures.webp', title: 'Кампании и Персонажи' },
    { url: 'campaign.webp', title: 'Экран Кампании' },
    { url: 'character-creation-1.webp', title: 'Создание Персонажа. Расы' },
    { url: 'character-creation-2.webp', title: 'Создание Персонажа. Классы' },
    { url: 'character-creation-3.webp', title: 'Создание Персонажа. Характеристики' },
  ],
};

export const CHARACTERS_PAGE: PageComponentData = {
  id: 'characters',
  nextId: 'spells',
  title: 'ГЕРОЙ — ЭТО ЛИЧНОСТЬ! И ОНА ПОЛНОСТЬЮ В ВАШИХ РУКАХ',
  content:
    'УПРАВЛЕНИЕ ПЕРСОНАЖЕМ В ИГРЕ - ЭТО ГЛУБОКО ПРОДУМАННАЯ СИСТЕМА ИНФОРМАЦИИ, РАСПРЕДЕЛЁННАЯ ПО УРОВНЯМ И ЗАДАЧАМ. ОБЩАЯ ИНФОРМАЦИЯ С СИЛЬНЫМИ И СЛАБЫМИ СТОРОНАМИ ВАШЕГО ГЕРОЯ, БОЕВОЙ ЭКРАН С СОСРЕДОТОЧЕНИЕМ ВАЖНЫХ ПАРАМЕТРОВ, СОЦИАЛЬНЫЙ ЭКРАН С ВАЖНЫМИ АТРИБУТАМИ ДЛЯ ОТЫГРЫША, ИНВЕНТАРЬ С ПРЕДМЕТАМИ ДЛЯ ТОРГОВЛИ.',
  images: [
    { url: 'character-about.webp', title: 'Просмотр Персонажа. Общий экран' },
    { url: 'character-combat.webp', title: 'Просмотр Персонажа. Боевой экран' },
    { url: 'character-creation-4.webp', title: 'Создание Персонажа. Предыстория' },
    { url: 'character-creation-5.webp', title: 'Создание Персонажа. Снаряжение' },
  ],
};

export const SPELLS_PAGE: PageComponentData = {
  id: 'spells',
  nextId: 'items',
  title: 'ПУТЕШЕСТВИЕ БЕЗ ЗАКЛИНАНИЙ — ЭТО ТАКЖЕ СТРАННО, КАК ТАВЕРНА БЕЗ ЭЛЯ!',
  content:
    'ЗАКЛИНАНИЯ — ЭТО НАША ОТДЕЛЬНАЯ ГОРДОСТЬ. ОНИ НЕВЕРОЯТНО ПРОРАБОТАННЫЕ, ОПИСАННЫЕ БОЛЕЕ ЧЕМ 50 ПАРАМЕТРАМИ. МОЩНЕЙШАЯ СИСТЕМА ФИЛЬТРОВ, ЧТОБЫ ВЫ МОГЛИ НАЙТИ ИМЕННО ТО ЗАКЛИНАНИЕ И С ТЕМ РЕДКИМ ЭФФЕКТОМ, КОТОРОЕ ВАМ НУЖНО. МОЩНЕЙШАЯ ВИЗУАЛЬНАЯ СОСТАВЛЯЮЩАЯ, РАЗРАБОТАННАЯ, ЧТОБЫ ДЛЯ ПОЛУЧЕНИЯ ОСНОВНОЙ ИНФОРМАЦИИ ВАМ ПОТРЕБОВАЛОСЬ ВСЕГО СЕКУНДА. ДАЖЕ БЕЗ ЧТЕНИЯ ОПИСАНИЯ.',
  images: [
    { url: 'spells.webp', title: 'Список Заклинаний' },
    { url: 'spells-view.webp', title: 'Просмотр Заклинания' },
    { url: 'spells-filters.webp', title: 'Фильтры Заклинаний' },
  ],
};

export const ITEMS_PAGE: PageComponentData = {
  id: 'items',
  nextId: 'revolution',
  title: 'ВАШИ СУМКИ ПОЛНЫ ВСЯКОГО ДОБРА, А НАША БАЗА ЗНАНИЙ ПОЛНА ПРЕДМЕТОВ',
  content:
    'А ЭТО ЗНАЧИТ, ЧТО ВАШИ ПЕРСОНАЖИ, NPC, ТОРГОВЦЫ, АНТАГОНИСТЫ И КАМПАНИИ В ЦЕЛОМ БУДУТ ПОЛНЫ SRD ПРЕДМЕТОВ С ИХ ХАРАКТЕРИСТИКАМИ, ВОЗМОЖНОСТЬЮ ДЕГРАДИРОВАТЬ СО ВРЕМЕНЕМ ИЛИ УЛУЧШАТЬСЯ. ОДНАКО НАСТОЯЩИЙ БРИЛЛИАНТ — СОЗДАНИЕ ВАШИХ ЛИЧНЫХ ПРЕДМЕТОВ, КОТОРЫЕ МОГУТ СУЩЕСТВОВАТЬ В ЕДИНИЧНОМ ЭКЗЕМПЛЯРЕ ВО ВСЕМ ВАШЕМ МИРЕ, ЧТО ДАЁТ НОВЫЕ ВОЗМОЖНОСТИ ДЛЯ ИНТЕРЕСНЫХ ИГРОВЫХ ПОВОРОТОВ.',
  images: [
    { url: 'items.webp', title: 'Предметы' },
    { url: 'items-view.webp', title: 'Просмотр Предмета' },
  ],
};

export const PAGES = [ADVENTURES_PAGE, CHARACTERS_PAGE, SPELLS_PAGE, ITEMS_PAGE];
