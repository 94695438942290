import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class VideoComponent {
  @Input() youtube = false;

  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;

  isPlaying = false;

  play() {
    this.video.nativeElement.play().then(() => (this.isPlaying = true));
    this.isPlaying = true;
  }

  pause() {
    this.video.nativeElement.pause();
    this.isPlaying = false;
  }
}
