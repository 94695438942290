import { Component } from '@angular/core';

import { FirstComponent } from './screens/first/first.component';
import { GreetingsComponent } from './screens/greetings/greetings.component';
import { PageComponent } from './screens/page/page.component';
import { LastComponent } from './screens/last/last.component';
import { PAGES } from './screens/pages';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [FirstComponent, GreetingsComponent, PageComponent, LastComponent],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
  host: { class: 'app-about' },
})
export class AboutComponent {
  pages = PAGES;
}
