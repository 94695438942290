import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { SocialsComponent } from '../../components/socials/socials.component';
import { ScrollerComponent } from '../../components/scroller/scroller.component';

@Component({
  standalone: true,
  selector: 'app-first',
  templateUrl: './first.component.html',
  styleUrls: ['./first.component.scss'],
  host: { class: 'app-about-page app-about-page-fullheight' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SocialsComponent, ScrollerComponent, NgOptimizedImage],
})
export class FirstComponent {}
