<div>
  <div class="app-container">
    <div class="app-site-name">
      <div class="app-site-name-dash"></div>
      DND campaign
    </div>
  </div>
  <div class="app-container">
    <h1>ДНД КАМПЭЙН* ПРИЗВАН ПОМОЧЬ МАСТЕРАМ ВЕСТИ ИГРЫ, А ИГРОКАМ - ВЕСТИ ПЕРСОНАЖЕЙ</h1>
  </div>
  <div class="app-container">
    <div class="blocks">
      <div class="block">
        <p>
          МАСТЕРА МОГУТ ВЕСТИ СВОИ ИГРЫ ОТ А ДО Я. СОЗДАЙТЕ ВАШУ КАМПАНИЮ, ДОБАВЬТЕ ВАШИХ ДРУЗЕЙ И ИХ ПЕРСОНАЖЕЙ.
          ПРОЕКТИРУЙТЕ ТОРГОВЦЕВ, АНТАГОНИСТОВ И ДРУГИХ NPC. СОЗДАВАЙТЕ УНИКАЛЬНЫЕ ПРЕДМЕТЫ, ЗАКЛИНАНИЯ И ТВАРЕЙ.
          ОТСЛЕЖИВАЙТЕ ПЕРЕДВИЖЕНИЯ ИГРОКОВ, ВЕДИТЕ ЗАМЕТКИ. <span class="app-text-accent">СОЗДАВАЙТЕ ВАШ МИР DND ПО
            ВАШИМ ПРАВИЛАМ!</span>
        </p>
      </div>
      <div class="block">
        <p>
          ПЕРЕНОСИТЕ ВАШИХ ПЕРСОНАЖЕЙ С БУМАЖНЫХ ЛИСТОВ, НАЗНАЧАЙТЕ ИМ СПОСОБНОСТИ, ЗАКЛИНАНИЯ И ПРЕДМЕТЫ. ВАШИ БОЕВЫЕ
          СПОСОБНОСТИ, СОЦИАЛЬНЫЕ НАВЫКИ ИЛИ ТОРГОВЫЕ ВОЗМОЖНОСТИ ВСЕГДА БУДУТ У ВАС ПОД РУКОЙ, А НЕ ГДЕ-ТО В
          БЕСКОНЕЧНЫХ БЛОКНОТАХ И КНИГАХ. <span class="app-text-accent">ПРИСОЕДИНЯЙТЕСЬ - И ВАШ ИГРОВОЙ ОПЫТ ИЗМЕНИТСЯ
            НАВСЕГДА!</span>
        </p>
      </div>
      <div class="block">
        <p>
          НЕВЕРОЯТНЫЕ ВОЗМОЖНОСТИ ПО ПОДДЕРЖКЕ ИГРОВЫХ СЕССИЙ. ВЕСЬ ЛОР ИЗ SRD, МОЩНЕЙШИЕ ФИЛЬТРЫ ДЛЯ ПОИСКА,
          ИЗМЕНЯЕМОСТЬ ВСЕГО, ЧТО ТОЛЬКО МОЖНО ПРЕДСТАВИТЬ, И ВАШ ЛИЧНЫЙ КОНТЕНТ, ДОСТУПНЫЙ ТОЛЬКО ВАМ И ВНУТРИ ВАШИХ
          ИГР. <span class="app-text-accent">ВЫ БУДЕТЕ В ВОСТОРГЕ ОТ ЭТОГО СЕРВИСА!</span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="app-about-page-container-fill">
  <div class="app-container">
    <div class="video-container">
      <app-video></app-video>
    </div>
    <div class="app-container">
      <span class="app-text-secondary">* DND Campaign - произносится как ДНД Кампэйн</span>
    </div>
  </div>
</div>
<app-scroller data="adventures"
  color="red">
</app-scroller>