import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, switchMap, take, throwError } from 'rxjs';

import { ApiService } from '@api/services';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private tokenSource$ = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private apiService: ApiService) {}

  intercept(source: HttpRequest<any>, handler: HttpHandler) {
    const request = this.getAuthRequest(source, this.authService.getAccessToken());

    return handler.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.name === 'HttpErrorResponse' && error.status === 401) {
          return this.handle401Error(request, handler);
        } else return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.tokenSource$.next(null);

      const authData = this.authService.getAuthData();

      if (authData)
        return this.apiService.refreshToken(authData).pipe(
          switchMap(data => {
            this.authService.authenticate(data);
            this.tokenSource$.next(data.access_token);
            return next.handle(this.getAuthRequest(request, data.access_token));
          }),
          catchError(error => {
            this.authService.logout();
            return throwError(() => new Error(error));
          }),
          finalize(() => (this.isRefreshing = false))
        );
      else {
        this.isRefreshing = false;
        this.authService.logout();
      }
    }

    return this.tokenSource$.pipe(
      filter(token => !!token),
      take(1),
      switchMap(token => next.handle(this.getAuthRequest(request, token)))
    );
  }

  private getAuthRequest(request: HttpRequest<any>, token?: string) {
    return token ? request.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : request;
  }
}
