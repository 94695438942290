<div>
  <div class="app-container">
    <div class="app-site-name">
      <div class="app-site-name-dash"></div>
      DND campaign
    </div>
  </div>
  <div class="app-container">
    <h1>{{data.title}}</h1>
  </div>
  <div class="app-container">
    <p>{{data.content}}</p>
  </div>
</div>
<div class="app-about-page-container-fill">
  <app-carousel [images]="data.images"></app-carousel>
</div>
<app-scroller [data]="data.nextId"></app-scroller>