import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { SVG_ICONS } from './svg-icons';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class SvgIconsModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Optional() @SkipSelf() parentModule?: SvgIconsModule
  ) {
    if (parentModule) throw new Error('SvgIconsModule is already loaded. Import it in the AppModule only');
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    this.registerIcons();
  }

  private registerIcons() {
    SVG_ICONS.forEach(item =>
      this.iconRegistry.addSvgIcon(
        item.split('/').reverse()[0],
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${item}.svg`)
      )
    );
  }
}
