import { Routes } from '@angular/router';
import { AboutComponent } from './site/about/about.component';

export const routes: Routes = [
  { path: '', redirectTo: '/spells', pathMatch: 'full' },
  { path: 'login', redirectTo: '/spells?login=modal' },
  { path: 'register', redirectTo: '/spells?register=modal' },
  { path: 'about', component: AboutComponent },
  {
    path: 'admin',
    loadChildren: () => import('./site/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '',
    loadChildren: () => import('./site/pages/pages.module').then(m => m.PagesModule),
  },
  { path: '**', redirectTo: '/not-found' },
];
