<div>
  <div class="app-container app-header-bar">
    <div class="app-header-bar-item"></div>
    <h5 class="app-header-bar-title app-font-regular">
      СМЕЛЫЙ, МОЩНЫЙ, НЕВЕРОЯТНЫЙ! МЫ - ЭТО РЕВОЛЮЦИЯ В РУ-СЕГМЕНТЕ DND
    </h5>
    <div class="app-header-bar-item">
      <a class="app-header-bar-social"
        href="//boosty.to/dnd-campaign"
        target="_blank">
        <div class="app-font-regular">Поддержите нас</div>
        <img src="assets/icons/social/boosty-big-dark.svg"
          alt="Подписаться на DND Campaign на Boosty">
      </a>
    </div>
  </div>
  <div class="app-container">
    <div class="app-site-name">
      <div class="app-site-name-dash"></div>
      DND campaign
    </div>
  </div>
  <div class="app-container">
    <h1>И ЭТО ВСЁ НА САМОМ ДЕЛЕ - НЕ ВСЁ, А ТОЛЬКО МАЛЕНЬКАЯ ЧАСТЬ <span class="app-text-accent">РЕВОЛЮЦИИ!</span>
    </h1>
  </div>
  <div class="app-container social-container">
    <p>
      ПРИСОЕДИНЯЙТЕСЬ К БЕТА-ТЕСТИРОВАНИЮ, СЛЕДИТЕ ЗА ОБНОВЛЕНИЯМИ СЕРВИСА В СОЦИАЛЬНЫХ СЕТЯХ, ПОДДЕРЖИВАЙТЕ НАС НА
      БУСТИ!
    </p>
    <app-socials></app-socials>
  </div>
  <div class="app-container app-link-container">
    <a class="app-link"
      routerLink="/">
      Начать приключение в сервисе
    </a>
  </div>
</div>
<div class="app-about-page-container-image"></div>