import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-socials',
  templateUrl: './socials.component.html',
  host: { class: 'app-socials' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialsComponent {}
