export const SVG_ICONS = [
  'boosty/boosty-color',
  'boosty/boosty-white',
  'boosty/boosty-dark',
  'classes/actificer',
  'classes/barbarian',
  'classes/bard',
  'classes/cleric',
  'classes/druid',
  'classes/fighter',
  'classes/monk',
  'classes/paladin',
  'classes/ranger',
  'classes/rogue',
  'classes/sorcerer',
  'classes/warlock',
  'classes/wizard',
  'effects/effect_acid',
  'effects/effect_bludgeoning',
  'effects/effect_cold',
  'effects/effect_fire',
  'effects/effect_force',
  'effects/effect_healing',
  'effects/effect_lightning',
  'effects/effect_necrotic',
  'effects/effect_piercing',
  'effects/effect_poison',
  'effects/effect_poison2',
  'effects/effect_psychic',
  'effects/effect_radiant',
  'effects/effect_slashing',
  'effects/effect_thunder',
  'areas/area_cone',
  'areas/area_cube',
  'areas/area_cylinder',
  'areas/area_line',
  'areas/area_sphere',
  'areas/area_square',
  'actions/action',
  'actions/reaction',
  'actions/bonus_action',
  'actions/time',
  'agility',
  'armor_lvl_1',
  'armor_lvl_2',
  'bag',
  'bestiary',
  'campaign_npc_list',
  'campaign_npc_bad',
  'campaign_npc_good',
  'd20',
  'dist',
  'duration',
  'endurance',
  'heroes_list',
  'history',
  'home',
  'intelligence',
  'items',
  'lorh',
  'map',
  'meetings',
  'news',
  'notes',
  'passive_focus',
  'speed',
  'spell',
  'strength',
  'stub',
  'wisdom',
];
