@if(images.length) {
@if(images.length > 1) {
<div class="app-carousel-navigation"
  (click)="prevImg()">
  <svg xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px">
    <path d="M640-80 240-480l400-400 71 71-329 329 329 329-71 71Z" />
  </svg>
</div>
<div class="app-carousel-navigation"
  (click)="nextImg()">
  <svg xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px">
    <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
  </svg>
</div>
}
<div class="app-carousel-container">
  @if(image) {
  <div @carouselImageTrigger
    class="app-carousel-image"
    [title]="image.title"
    [style.background-image]="'url(assets/about/pages/' + image.url + ')'">
  </div>
  }
</div>
<div class="app-carousel-counter">{{index + 1}}/{{this.images.length}}</div>
}